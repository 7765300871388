<template>
  <BodySimple>
    <template #header>
      <h2 class="text-xl font-bold">قوانین و مرامنامه</h2>
      <ul class="list-inside list-disc mt-5">
        <li>
          <router-link :to="{ hash: '#group_ad' }" class="text-sky-500">
            قوانین مربوط به تبلیغات
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#group_bot' }" class="text-sky-500">
            قوانین مربوط به ربات گروه
          </router-link>
        </li>
        <li>
          <router-link :to="{ hash: '#channel_bot' }" class="text-sky-500">
            قوانین مربوط به ربات کانال
          </router-link>
        </li>
      </ul>
    </template>
    <template #default>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md"
        id="group_ad"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/business-startup.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">قوانین مربوط به تبلیغات</h2>
        </div>

        <h2 class="text-lg font-bold p-5">نکات مهم سفارش تبلیغ</h2>
        <ul class="list-inside list-disc leading-10">
          <li>
            قبل از سفارش تبلیغ حتما قسمت سوالات متداول تبلیغات را به طور کامل مطالعه کنید.
            تبلیغات واضح و شفاف است پس در صورت ناراضی بودن از تبلیغ مقصر خود شما هستید و
            پولی به شما برگردانده نمی‌شود.
          </li>
          <li>
            اگر برای بار اول قصد خرید دارید پیشنهاد میکنیم تعرفه های ارزان قیمت را انتخاب
            کنید که در صورت نداشتن بازده پول زیادی از دست نداده باشید.
          </li>
          <li>
            اگر میخواید برای گروه تبلیغ کنید باید توجه داشته باشید ربات های مزاحم خزنده
            نیز وارد گروه شما می‌شوند پس باید بتوانید به خوبی گروه خود را مدیریت کنید و یک
            ربات مدیر گروه قدرتمند داشته باشید پیشنهاد ما ربات
            <a href="https://t.me/mnrg1bot" class="font-bold text-sky-500">@mnrg1bot</a>
            است
          </li>
        </ul>
        <h2 class="text-lg font-bold p-5">موارد غیر مجاز</h2>
        <p class="pb-5 text-red-600">
          تبلیغات در موارد زیر غیر قابل قبول است و در صورت تخلف تبلیغ شما حذف شده و پولی
          به شما بازگردانده نمی‌شود
        </p>
        <ul class="list-inside list-disc leading-10">
          <li>
            انتخاب موضوع غیر مرتبط با تبلیغ. اگر در انتخاب موضوع خود شک دارید حتما قبل از
            انتخاب به ادمین پیام دهید
          </li>
          <li>تبلیغات در مورد تبلیغ و عضو گیری در تلگرام و شبکه های اجتماعی دیگر</li>
          <li>تبلیغات در مورد ربات کانال و گروه</li>
          <li>تبلیغات ارزش افزوده</li>
          <li>تبلیغات بت و شرط بندی با مجوز یا بدون مجوز و...</li>
          <li>
            تبلیغات که به صورت مستقیم یا غیر مستقیم به دانلود برنامه اندروید مربوط باشد.
            تبلیغات برنامه های اندرویدی فقط از طریق لینک گوگل پلی و بازار ممکن است
          </li>
          <li>تبلیغات تلگرام های غیر رسمی</li>
          <li>
            تبلیغاتی که دارای متن یا عکس های زشت و زننده و مخالف قوانین کشور ایران باشد
          </li>
          <li>هرگونه تبلیغ سیاسی</li>
        </ul>
      </div>
      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="group_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">قوانین مربوط به ربات مدیریت گروه</h2>
        </div>
      </div>

      <div
        class="p-3 bg-blueGray-100 border border-blueGray-200 rounded-md mt-5"
        id="channel_bot"
      >
        <div class="text-lg font-bold flex items-center gap-2">
          <img src="@/assets/images/icons8-bot-96-blue.png" class="inline-block h-20" />
          <h2 class="text-lg font-bold">قوانین مربوط به ربات مدیریت کانال</h2>
        </div>
      </div>
    </template>
  </BodySimple>
</template>

<script>
import BodySimple from "@/layouts/BodySimple.vue";
export default {
  components: { BodySimple },
};
</script>
